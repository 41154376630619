@font-face {
  font-family: 'Oswald';
  src: local('Oswald'),
    url(./assets/fonts/Oswald/Oswald-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Oswald';
  font-weight: 300;
  src: local('Oswald'),
    url(./assets/fonts/Oswald/Oswald-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Oswald';
  font-weight: 700;
  src: local('Oswald'),
    url(./assets/fonts/Oswald/Oswald-Bold.ttf) format('truetype');
}

/* Paleta de colores para el modo claro */
:root {
  --primary: #293641;
  --primaryBackground: #f1f1f1;
  --secondaryBackground: #fff;
  --secondary: #fadc41;
  --accent: #e37222;
  --primaryText: #293641;
  --secondaryText: #969fa6;
  --warning: #ff5353;
  --button: #293641;
  --shadow: #c1c8ce;
}

/* Paleta de colores para el modo oscuro */
[data-theme='dark'] {
  --primary: #293641;
  --primaryBackground: #394a58;
  --secondaryBackground: #324250;
  --secondary: #fadc41;
  --accent: #e37222;
  --primaryText: #fff;
  --secondaryText: #969fa6;
  --warning: #ff5353;
  --button: #fadc41;
  --shadow: #c1c8ce;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
button {
  background-color: transparent;
  color: inherit;
  border-width: 0;
  padding: 0;
  cursor: pointer;
}
figure {
  margin: 0;
}
input::-moz-focus-inner {
  border: 0;
  padding: 0;
  margin: 0;
}
ul,
ol,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--accent);
  margin: 0;
  font-weight: 700;
}

h2 {
  font-size: 24rem;
}

a {
  color: var(--accent);
  font-weight: 700;
}
p {
  margin: 0;
}
cite {
  font-style: normal;
}
fieldset {
  border-width: 0;
  padding: 0;
  margin: 0;
}

html {
  font-size: 0.9px; /*for using REM units*/
  background-color: var(--primaryBackground);
}
body {
  font-family: Oswald, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 16rem;
  font-weight: 300;
  line-height: 1.3;
  color: var(--primaryText);
  margin: 0;
}
